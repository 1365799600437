import React, { useEffect } from "react";
import foto1 from "./../media/foto1.webp"; // Replace with the path to your background image
import foto2 from "./../media/foto2.webp"; // Replace with the path to your background image
import foto3 from "./../media/foto3.webp"; // Replace with the path to your background image
import foto4 from "./../media/foto5.webp"; // Replace with the path to your background image


// import bending from "./../media/bending.png"; // Replace with the path to your background image
// import laser from "./../media/laser.png"; // Replace with the path to your background image
// import pipe from "./../media/pipe.png"; // Replace with the path to your background image

import AOS from "aos";
import "aos/dist/aos.css";

const AboutUS = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="about" className="pt-10 md:pt-20 bg-black ">
      <div className="bg-black text-white">
        <div
          data-aos="fade-right"
          data-aos-duration="2000"
          className="lg:ml-40 lg:mr-40 flex flex-col md:flex-row items-start md:items-center md:justify-end md:space-x-8 p-8 my-10 md:my-20"
        >
          <div className="md:w-1/2">
            <h1 className="text-3xl md:text-4xl  my-8 md:my-0">About us</h1>
          </div>
          <div className="md:w-1/2 ">
            <p className="text-xl md:text-2xl">
            We combine various techniques and methods to fabricate metal parts, ensuring we meet our clients' demands and standards.
            </p>
          </div>
        </div>
        </div>
        <div className="lg:grid grid-cols-2">
        <div className=" w-full ">
        <img
          src={foto1}
          alt="Team"
          className="w-full lg:h-96 lg:object-cover "
        /> </div>
        <div className="hidden lg:block">
         <img
          src={foto2}
          alt="Team"
          className="w-full lg:h-96 lg:object-cover  "
        />
        </div>
        </div>
        <div>
        <p className="text-black bg-white text-center lg:text-2xl lg:pl-44 lg:pr-44 lg:py-20 py-10"> 
        We leverage our strategic location in Eastern Europe to offer high-quality sheet metal processing services at competitive prices. Our proximity to Western Europe allows us to efficiently manage logistics, ensuring timely deliveries and reduced transportation costs.</p>
        </div>
        <div className="lg:grid grid-cols-2">
        <div className=" w-full h-full ">
        <img
          src={foto3}
          alt="Team"
          className="w-full lg:h-96 object-cover "
        /> </div>
        <div className="hidden lg:block">
         <img
          src={foto4}
          alt="Team"
          className="w-full lg:h-96 lg:object-fill object-cover "
        />
        </div>
        </div>
        <div>
        <p className="text-white bg-black text-center lg:text-2xl lg:pl-44 lg:pr-44 lg:py-20 py-10"> 
        We have successfully completed a wide range of projects, including large scale production series and customized, smaller orders. Our past work reflects our commitment to precision, quality, and meeting the specific needs of our clients across various industries.</p>
        </div>
    </div>
  );
};

export default AboutUS;
