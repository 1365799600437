import React, { useEffect, useState } from "react";
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaLinkedin } from 'react-icons/fa';
import AOS from "aos";
import "aos/dist/aos.css";
const ContactUS = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="contact" className=" bg-black h-full xl:h-screen ">
      <div className="flex flex-col justify-between">
      <div className="text-white lg:pr-40 lg:pl-40 pb-28 md:pb-10 pt-0 md:pt-10">
        <div className="flex flex-col md:flex-row items-start md:items-center md:justify-end md:space-x-8 p-8 mt-20 md:my-20">
          <div data-aos="fade-right" data-aos-duration="2000" className="md:w-1/2">
            <h1 className="text-3xl md:text-4xl my-8 md:my-0">Contact</h1>
          </div>
          <div data-aos="fade-right" data-aos-duration="2000" className="md:w-1/2">
            <p className="text-xl md:text-2xl">
              Everyone we meet becomes a chapter in our story, just as we do in theirs. Join our journey, and let us collaborate to turn your next idea into reality.
            </p>
          </div>
        </div>
      </div>
      </div>
      <div className="bg-white flex items-center justify-center w-full py-28 md:py-20">
        <div data-aos="fade-right" data-aos-duration="2000" className="flex flex-col items-start space-y-4">
          <a href="mailto:contact@xakimetal.com" target={"_blank"} className="flex items-center space-x-2 text-black">
            <FaEnvelope className="text-lg md:text-xl text-black hover:text-nav" />
            <span className="text-base md:text-xl text-black hover:text-nav">eldar.sulejmani@xakimetal.com</span>
          </a>
          <a href="tel:0038971984771" className="flex items-center space-x-2">
            <FaPhone className="text-lg md:text-xl text-black hover:text-nav" />
            <span className="text-base md:text-xl text-black hover:text-nav">+38971984771</span>
          </a>
          <a href="https://maps.app.goo.gl/tehbhtfY2KMh5hCfA" target={"_blank"} className="flex items-center space-x-2">
            <FaMapMarkerAlt className="text-lg md:text-xl text-black hover:text-nav" />
            <span className="text-base md:text-xl text-black hover:text-nav">Gjoce Stojcheski, Tetovo 1220, North Macedonia</span>
          </a>
          <a href="https://www.linkedin.com/company/xaki-metal/" target={"_blank"} className="flex items-center space-x-2">
            <FaLinkedin className="text-lg md:text-xl text-black hover:text-nav" />
            <span className="text-base md:text-lg text-black hover:text-nav">LinkedIn</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUS;